<template>
  <div class="configEnterprise">
    <el-tabs v-model="activeName" @tab-click="tabChanged">
      <!-- 自动匹配 -->
      <el-tab-pane name="first">
        <span slot="label"
          ><i class="el-icon-date"></i> 自动匹配 ({{ tableData[0] ? tableData.length : 0 }})</span
        >
        <div class="clue_card">
          <div class="card_left">
            <!-- 企业图片区域 -->
            <div class="clue_img">
              <div
                class="img_left_top one"
                v-if="cardInfo.clueState == 1 || cardInfo.clueState == null"
              >
                <span>邀约中</span>
              </div>
              <div class="img_left_top two" v-if="cardInfo.clueState == 2">
                <span>进行中</span>
              </div>
              <div class="img_left_top three" v-if="cardInfo.clueState == 3">
                <span>已完结</span>
              </div>
              <div class="img_left_top four" v-if="cardInfo.clueState == 4">
                <span>已终止</span>
              </div>
              <img :src="cardInfo.clueImage" alt="图片" />
            </div>
            <!-- 企业信息 -->
            <div class="card_info">
              <div class="card_title row">
                <div>
                  <span class="clue_name">{{ cardInfo.clueName }}</span>
                </div>
                <div>
                  <div class="clue_num" v-if="cardInfo.generalPledgePrice">
                    <span>押金￥{{ cardInfo.generalPledgePrice }}</span>
                  </div>
                  <div v-else>
                    <div class="clue_num">
                      <img src="@/assets/image/clue-free.png" alt="图片" />
                      <span>押金免费</span>
                    </div>
                  </div>
                  <div class="clue_num" v-if="cardInfo.generalCluePrice">
                    <span>线索费￥{{ cardInfo.generalCluePrice }} </span>
                  </div>
                  <div v-else>
                    <div class="clue_num">
                      <img src="@/assets/image/clue-free.png" alt="图片" />
                      <span>线索免费</span>
                    </div>
                  </div>
                </div>
              </div>
              <el-row class="row" v-if="cardInfo.clueTableList">
                <div v-for="(i1, i2) in cardInfo.clueTableList" :key="i2">
                  <el-tag effect="plain" v-if="i2 <= 3" style="margin-right: 5px">
                    {{ i1 }}
                  </el-tag>
                </div>
                <div @mouseenter="showTags" @mouseleave="hideTags" class="show_tags">
                  <el-tag style="cursor: pointer; background: #fff"
                    >{{ cardInfo.clueTableList.length }}+</el-tag
                  >

                  <!-- 要展示的更多标签 -->
                  <div class="tags_box" v-show="isShowTags">
                    <el-tag effect="plain" v-for="(i1, i2) in cardInfo.clueTableList" :key="i2">
                      {{ i1 }}
                    </el-tag>
                  </div>
                </div>
              </el-row>
              <!-- 公司名称|官网 -->
              <div style="display: flex" class="row">
                <div class="corp_name">{{ cardInfo.companyFullName }}</div>
                <div style="padding: 0 10px">
                  {{ cardInfo.customerContactPerson }}({{ cardInfo.associatePhone }})
                </div>
              </div>
              <div class="bottom_row">
                <span style="margin-right: 20px">
                  所在地区：{{ cardInfo.province }}-{{ cardInfo.city }}
                </span>
                <span>有效期：{{ cardInfo.clueStartTime }}至{{ cardInfo.clueEndTime }}</span>
              </div>
            </div>
          </div>
          <!-- 右侧按钮 -->
          <div class="card_right">
            <div class="btn_list">
              <el-button type="primary" @click="insertSelected">加入已选</el-button>
              <el-button @click="updateStage" plain type="primary">设置关联度</el-button>
            </div>
            <!-- <div class="relevance">
              <span>关联度98%</span>
            </div> -->
          </div>
        </div>
        <el-card>
          <div class="card_top">
            <div class="left">
              <span style="padding-right: 20px">关联度:</span>
              <el-radio-group v-model="queryInfo.correlation" @change="searchMatched">
                <el-radio :label="''">全部</el-radio>
                <el-radio :label="1">只选90%以上</el-radio>
                <el-radio :label="2">只选80%以上</el-radio>
                <el-radio :label="3">只选70%以上</el-radio>
              </el-radio-group>
            </div>
            <div class="right">
              <span>最新刷新时间：{{ newtime }}</span>
            </div>
          </div>
          <div class="search_right">
            <el-input placeholder="请输入企业名称" v-model="queryInfo.companyFullName">
              <el-button class="seach" slot="append" @click="searchMatched" type="primary"
                >搜索</el-button
              >
            </el-input>
          </div>
          <!-- 表单区域 -->
          <el-table
            v-loading="loading"
            element-loading-text="匹配中"
            :data="tableData"
            ref="tableRef"
            :row-key="getOpenKey"
            @selection-change="changeFunOpen"
          >
            <el-table-column align="center" type="selection" width="60" :reserve-selection="true">
            </el-table-column>
            <el-table-column prop="id" align="center" width="100" label="编号"></el-table-column>
            <el-table-column label="logo" align="center" width="100">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <img
                    width="52"
                    height="52"
                    v-if="scope.row.companyLogo"
                    :src="scope.row.companyLogo"
                    alt="logo"
                  />
                  <img
                    width="52"
                    height="52"
                    v-else
                    src="../../../assets/image/Group 3237.png"
                    alt=""
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              width="200"
              prop="companyFullName"
              label="企业名称"
            ></el-table-column>
            <el-table-column
              align="center"
              width="160"
              prop="officialWebsite"
              label="企业官网"
            ></el-table-column>
            <el-table-column align="center" width="120" label="所在城市">
              <template slot-scope="scope">
                <span>{{ scope.row.province }}-{{ scope.row.city }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="标签">
              <template v-slot="{ row }">
                <span v-for="(o, index) in row.companyLabel" :key="index">
                  <span v-if="index < 4 && o != ''" class="span_tag">#{{ o }}</span>
                </span>
                <el-popover placement="bottom" width="230" trigger="hover">
                  <div
                    class="hidden_label_box"
                    style="
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-content: center;
                      flex-wrap: wrap;
                    "
                  >
                    <span
                      class="span_tag"
                      v-for="(o, index) in row.companyLabel && row.companyLabel.length
                        ? row.companyLabel.filter((el, index) => index > 3)
                        : []"
                      :key="index"
                      style="
                        font-family: 'PingFang SC';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #4e93fb;
                        margin-right: 10px;
                      "
                      >#{{ o }}</span
                    >
                  </div>
                  <span
                    style="color: #4e93fb"
                    slot="reference"
                    v-if="row.companyLabel && row.companyLabel.length > 4"
                    >...{{ row.companyLabel.length - 4 }}</span
                  >
                </el-popover>
              </template>
            </el-table-column>

            <el-table-column prop="correlation" width="100" label="关联度" sortable align="center">
              <template slot-scope="scope">
                <span class="correlation_box" v-if="scope.row.correlation == 1">0-20%</span>
                <span class="correlation_box" v-if="scope.row.correlation == 2">20%-40%</span>
                <span class="correlation_box" v-if="scope.row.correlation == 3">40%-60%</span>
                <span class="correlation_box" v-if="scope.row.correlation == 4">60%-80%</span>
                <span class="correlation_box" v-if="scope.row.correlation == 5">80%-100%</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="企业简介">
              <template slot-scope="scope">
                <div style="display: flex; justify-content: center">
                  <div
                    class="enterpriseIntro"
                    @mouseenter="enterpriseIntroShow = scope.row.id"
                    @mouseleave="enterpriseIntroShow = -1"
                  >
                    {{ scope.row.enterpriseIntro }}
                  </div>
                  <div
                    class="enterpriseIntroShow"
                    v-show="enterpriseIntroShow == scope.row.id"
                    @mouseenter="enterpriseIntroShow = scope.row.id"
                    @mouseleave="enterpriseIntroShow = -1"
                  >
                    {{ scope.row.enterpriseIntro }}
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页区域 -->
          <div class="new_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[4, 10, 30]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div>
        </el-card>
      </el-tab-pane>

      <!-- 确认已选 -->
      <el-tab-pane name="second">
        <span slot="label"
          ><i class="el-icon-date"></i> 确认已选 ({{ checkList[0] ? checkList.length : 0 }})</span
        >
        <div class="selected_top">
          <div class="selected_top_left">
            <el-button type="primary" @click="notRelease">发布已选</el-button>
            <el-button type="primary" plain @click="setPrice">设置金额</el-button>
            <el-button type="warning" plain @click="deleteids">x 移除已选</el-button>
            <div class="ckeckbox_text">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                >全选</el-checkbox
              >
            </div>
            <div class="clue_num" v-if="cluePrice.clueDepositPrice">
              <span class="set_font">默认押金￥</span>
              <span>{{ cluePrice.clueDepositPrice }}</span>
            </div>
            <div class="clue_num" v-else>
              <span class="set_font">默认押金</span>
              <span>免费</span>
            </div>
            <div class="clue_num" v-if="cluePrice.clueSmartPrice">
              <span class="set_font">默认线索费￥ </span>
              <span>{{ cluePrice.clueSmartPrice }}</span>
            </div>
            <div class="clue_num" v-else>
              <span class="set_font">默认线索费</span>
              <span> 免费</span>
            </div>
          </div>
          <div class="search_right">
            <el-input
              placeholder="请输入企业"
              v-model="queryInfo.companyFullName"
              class="input-with-select"
            >
              <el-button class="seach" slot="append" @click="searchSelected()">搜索</el-button>
            </el-input>
          </div>
        </div>
        <!-- 企业卡片 -->
        <div class="card_item" v-for="item in checkList" :key="item.id">
          <div class="card_header">
            <div class="item_checkbox">
              <el-checkbox
                @change="latechange($event, item)"
                v-model="item.checked1"
                label=""
              ></el-checkbox>
            </div>
            <div class="selected_card">
              <div class="selected_right_top" v-if="item.correlation">
                推荐度:
                <span  v-if="item.correlation == 1">0-20%</span>
                <span  v-if="item.correlation == 2">20%-40%</span>
                <span v-if="item.correlation == 3">40%-60%</span>
                <span  v-if="item.correlation == 4">60%-80%</span>
                <span  v-if="item.correlation == 5">80%-100%</span>
             
              </div>
              <div class="selected_card_left">
                <!-- 企业图片区域 -->
                <div class="logo">
                  <img v-if="item.companyLogo" :src="item.companyLogo" />
                  <img
                    width="52"
                    height="52"
                    v-else
                    src="../../../assets/image/Group 3237.png"
                    alt=""
                  />
                </div>
                <!-- 企业信息 -->
                <div class="card_info">
                  <div class="card_title">
                    <span class="name">{{ item.companyFullName }}</span>
                    <span class="path">{{ item.province }}-{{ item.city }}</span>
                  </div>
                  <div class="card_tag" v-if="item.companyLabel">
                    <div
                      class="card_tag_flex"
                      :style="index > 2 ? 'display:none' : ''"
                      v-for="(o, index) in item.companyLabel"
                      :key="index"
                    >
                      <span v-if="index < 3">{{ o }}</span>
                    </div>
                    <el-popover placement="right" width="430" trigger="hover">
                      <div
                        class="hidden_label_box"
                        style="
                          width: 100%;
                          display: flex;
                          flex-direction: row;
                          align-content: center;
                          flex-wrap: wrap;
                        "
                      >
                        <span
                          class="office"
                          style="
                            border: 1px solid #559eff;
                            color: #4e93fb;
                            box-sizing: border-box;
                            padding: 1px 6px;
                            display: block;
                            font-size: 14px;
                            font-weight: 400;
                            border-radius: 4px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                          "
                          v-for="(o, index) in item.companyLabel"
                          :key="index"
                          >{{ o }}</span
                        >
                      </div>

                      <span
                        class="office"
                        slot="reference"
                        v-if="item.companyLabel && item.companyLabel.length >= 3"
                        >{{ item.companyLabel.length }}+</span
                      >
                    </el-popover>
                  </div>
                </div>
              </div>
              <!-- 右侧按钮 -->
              <div class="selected_card_right">
                <el-button v-if="item.clueDepositPrice"
                  >押金¥ {{ item.clueDepositPrice }}</el-button
                >
                <el-button v-else>押金¥ 免费</el-button>

                <el-button v-if="item.clueSmartPrice">线索费¥ {{ item.clueSmartPrice }}</el-button>
                <el-button v-else>线索费¥ 免费</el-button>

                <span class="remove" @click="deleteById(item)">移除</span>
              </div>
            </div>
          </div>
      
        </div>
        <!-- 分页区域 -->
        <div class="new_page">
          <el-pagination
            :current-page="queryInfo.pageNum"
            :page-sizes="[4, 10, 30]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="checkToatl"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </el-tab-pane>

      <el-dialog
        title="设置关联度"
        :visible.sync="dialogVisible"
        width="40%"
        @close="handleCloseMenu"
      >
        <el-form ref="form" :model="param" :rules="menuRules" label-width="130px" :inline="true">
          <el-form-item label="关联度" prop="correlation">
            <el-radio-group v-model="param.correlation">
              <el-radio v-for="item in codeType" :key="item.id" :label="item.id">{{
                item.val
              }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button type="primary" @click="submitForm">确 定</el-button>
          <el-button @click="dialogVisible = false">取 消</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="批量设置金额"
        :visible.sync="dialogVisibleprice"
        width="30%"
        @close="handleClose"
      >
        <div class="cluePrice_flex">
          <div>
          <span>默认押金</span>
          <span class="price_color">¥{{ cluePrice.clueDepositPrice }}</span>
        </div>
        <div>
          <span>默认线索费</span>
          <span class="price_color">¥{{ cluePrice.clueSmartPrice }}</span>
        </div>
        </div>
        <el-form ref="formprice" :model="formprice" label-width="100px" :inline="true">
          <el-form-item>
            <el-input placeholder="请输入内容" v-model="formprice.clueDepositPrice">
              <template slot="prepend">押金¥</template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input placeholder="请输入内容" v-model="formprice.clueSmartPrice">
              <template slot="prepend">费用¥</template>
            </el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" style="display: flex;justify-content: center;">
          <el-button type="primary" @click="updatePrice">确 定</el-button>
          <el-button @click="dialogVisibleprice = false">取 消</el-button>
        </div>
      </el-dialog>
    </el-tabs>
  </div>
</template>
<script>
import {
  correlation,
  addJoin,
  serverApiSelectedCompanyPage,
  deleteById,
  listPage,
  editPrice,
  notRelease,
  listTopById,
  ListPrice
} from '@/api/clue.js'
const defaultQueryInfo = Object.freeze({
  smartId: '',
  orders: '1', //排序 默认传1 2->关联度升序;3->关联度降序
  pageNum: 1,
  correlation: '', //关联度 1->只选90%以上; 2->只选80%以上; 3->只选70%以上
  pageSize: 10,
  companyFullName: ''
})
export default {
  name: 'configEnterprise',
  computed: {
    smartId() {
      return this.$route.query.id
    },
    newtime() {
      return this.$route.query.newtime
    }
  },
  data() {
    return {
      activeName: 'first',
      queryInfo: { ...defaultQueryInfo },
      //线索信息
      cardInfo: {},
      loading: false, //loading
      //控制显示更多标签
      isShowTags: false,
      //表单数据
      tableData: [],
      total: 0,
      //自动匹配 已选中的企业信息列表
      selectedList: [],
      //加入已选按钮的请求参数
      addObj: {
        paramList: [],
        smartId: ''
      },
      param: {
        correlation: '',
        ids: []
      }, //批量设置关联度
      dialogVisible: false,
      idsList: [], //已选企业id集合
      paramList: [], //移出已选集合
      cluePrice: {}, //金额
      dialogVisibleprice: false, //批量设置金额
      checkToatl:0,//已选企业数
      formprice: {
        clueDepositPrice: 0,//线索押金
        clueSmartPrice: 0,//线索费用
        ids: []
      }, //批量设置金额
      menuRules: {
        correlation: [{ required: true, message: '请选择关联度', trigger: 'change' }]
      },
      codeType: [
        {
          id: 1,
          val: '0-20%'
        },
        {
          id: 2,
          val: '20%-40%'
        },
        {
          id: 3,
          val: '40%-60%'
        },
        {
          id: 4,
          val: '60%-80%'
        },
        {
          id: 5,
          val: '80%-100%'
        }
      ],
      //确认已选界面选中的企业
      checkList: [],
      enterpriseIntroShow: false,
      checkAll: false,
      isIndeterminate: false,
 
    }
  },
  created() {
    this.listTopById()
    this.ListPrice()
    this.searchMatched()
    this.searchSelected()
   
  },

  methods: {
    //头部详情
  async  listTopById(){
    const query={
      id:this.smartId
    }
    const {data:res}=await listTopById(query)
    if(res.resultCode==200){
      this.cardInfo=res.data
    }
    },
    //tab页签切换
    tabChanged() {
      if (this.activeName == 'first') {
        this.searchMatched()
        this.$refs.tableRef.clearSelection()
      } else {
        this.searchSelected()
      }
    },
    //查询金额
    async ListPrice() {
      const query = {
        smartId: this.smartId
      }
      const { data: res } = await ListPrice(query)
      if (res.resultCode == 200) {
        this.cluePrice = res.data
      }
    },
    //查询自动匹配的企业
    async searchMatched() {
      this.loading = true
      this.queryInfo.smartId = this.smartId
      const { data: res } = await listPage(this.queryInfo)
      if (res.resultCode == 200) {
        this.loading = false
        this.tableData = res.data.list
        this.total = res.data.total
        
        //选中table表格所有行
        this.$nextTick(() => {
          for (let i = 0; i < this.tableData.length; i++) {
            this.$refs.tableRef.toggleRowSelection(this.tableData[i], true)
          }
        })
      } else {
        this.$message.error('获取自动匹配信息失败！')
      }
    },
    //查询已经加入已选的企业
    async searchSelected() {
      const { data: res } = await serverApiSelectedCompanyPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.checkList = res.data.list
        this.checkToatl=res.data.total
        this.checkList.forEach((item) => {
          this.$set(item, 'checked1', false)
        })
        this.checkAll=false
      } else {
        this.$message.error('查询失败！')
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.searchMatched()
      this.searchSelected()
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.searchMatched()
      this.searchSelected()
    },
    showTags() {
      this.isShowTags = true
    },
    hideTags() {
      this.isShowTags = false
    },
    // 指定一个key标识这一行的数据
    getOpenKey(row) {
      return row.id
    },
    // 获取选中的企业信息、企业id
    changeFunOpen(val) {
      this.selectedList = val
      this.ids = val.map((item) => item.id)
      this.addObj.paramList = [] //选中值变化时先清空
    },
    //加入已选按钮
    async insertSelected() {
      console.log(this.selectedList);
      this.addObj.paramList=[]
      //判断是否有勾选企业
      if (!this.selectedList.length) {
        this.$message.error('请至少选择一个企业')
        return
      }
      this.addObj.smartId = this.smartId
      //判断有选中企业时将选中的企业id加入
      this.selectedList.forEach((item) => {
        this.addObj.paramList.push({
          companyId: item.companyId,
          correlation: item.correlation,
          id: item.id
        })
      })

      //传入线索id和选中的企业id数组
      const { data: res } = await addJoin(this.addObj)
      if (res.resultCode === 200) {
        this.searchMatched()
        this.activeName = 'second'
        this.searchSelected()
       
      } else {
        this.$message.error('企业加入已选失败！')
      }
    },
    //修改关联度
    updateStage(row) {
      if (!this.selectedList.length) {
        this.$message.error('请至少选择一个企业')
        return
      }
      this.param.correlation = ''
      this.dialogVisible = true
    },
    //取消弹窗
    handleCloseMenu() {
      this.dialogVisible = false
    },
    //设置管理度
    async submitForm() {
      if (!this.param.correlation) {
        this.$message.error('请选择关联度')
        return
      }
      this.param.ids = this.ids
      const { data: res } = await correlation(this.param)
      if (res.resultCode === 200) {
        this.searchMatched()
       
        this.dialogVisible = false
      } else {
        this.$message.error('设置关联度失败！')
      }
    },
    //批量设置金额
    setPrice() {
      this.dialogVisibleprice = true
      this.formprice.clueDepositPrice=this.cluePrice.clueDepositPrice
      this.formprice.clueSmartPrice=this.cluePrice.clueSmartPrice
    },
    //取消设置金额弹窗
    handleClose() {
      this.dialogVisibleprice = false
    },
    //设置金额保存
   async updatePrice(){
    this.formprice.ids=this.idsList
    const {data:res}=await editPrice(this.formprice)
    if(res.resultCode==200){
      this.$message.success('设置成功')
      this.dialogVisibleprice = false
      this.searchSelected()
      this.idsList=[]
    }else{
      this.$message.error(res.message)
    }
    },
    //选择已选企业
    latechange(bol, row) {
      const index = this.idsList.indexOf(row.id)
      if (index == -1) {
        this.idsList.push(row.id)
        this.paramList.push({
          id: row.id,
          companyId: row.companyId,
          smartId: this.smartId
        })
      } else {
        this.idsList.splice(index, 1)
        this.paramList.splice(index, 1)
      }
    },
    //全选
    handleCheckAllChange(val) {
      if (val) {
        this.checkList.forEach((item) => {
          this.idsList.push(item.id)
          this.paramList.push({
          id: item.id,
          companyId: item.companyId,
          smartId: this.smartId
        })
          this.$set(item, 'checked1', true)
        })
      } else {
        this.checkList.forEach((item) => {
          this.idsList = []
          this.paramList = []
          this.$set(item, 'checked1', false)
        })
      }
      this.isIndeterminate = val
    },
    //移出已选
    deleteById(row) {
      const list = [
        {
          id: row.id,
          companyId: row.companyId,
          smartId: this.smartId
        }
      ]

     
      this.$alert('您是否确认移出？')
        .then(function () {
          return deleteById(list)
        })
        .then(() => {
          this.searchMatched()
    this.searchSelected()
          this.$message.success('移出成功')
        })
        .catch(() => {})
    
    },
    //批量移出已选
    deleteids(row) {
      if(this.paramList.length){
      const that=this
      this.$alert('您是否确认移出？')
        .then(function () {
          return deleteById(that.paramList)
        })
        .then(() => {
          this.searchMatched()
    this.searchSelected()
          this.$message.success('移出成功')
        })
        .catch(() => {})
     }else{
      this.$message.success('请选择需要移出的企业')
     }
     
    },
    //发布已选
    notRelease(){
      if (this.idsList.length > 0) {
        const that = this
        this.$alert('是否确认发布已选？')
          .then(function () {
            return notRelease(that.idsList)
          })
          .then(() => {
            this.searchSelected()
            this.idsList = []
           
            this.$message.success('发布成功')
          })
          .catch(() => {})
      } else {
        this.$message.warning('请选择企业')
      }
    },
   
    //前往企业官网
    ToOfficialWebsite() {
      const newOpenWindow = window.open('about:blank')
      newOpenWindow.location = this.cardInfo.officialWebsite
    }
  }
}
</script>

<style lang="less" scoped>
 /deep/.el-checkbox__inner {
            width: 18px;
            height: 18px;
          }
          /deep/.el-checkbox__label {
            color: #333;
          }
          /deep/.el-checkbox__inner::after {
            height: 10px;
            left: 6px;
          }
 .configEnterprise {
  .cluePrice_flex{
    padding: 10px 20px;
    width: 220px;
    background: #fafafa;
    margin-bottom: 10px;
    div{
      margin-top: 5px;
    }
    .price_color{
      color: #212121;
      margin-left: 10px;
    }
  }
} 
.tags_box .el-tag {
  height: 18px;
  margin: 0 5px 5px 0;
  padding: 0 5px;
  line-height: 16px;
  font-size: 12px;
}
.el-tag {
  height: 18px;
  padding: 0 5px;
  line-height: 16px;
  font-size: 12px;
  color: #559eff;
}

.clue_card {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 20%;
  margin-bottom: 10px;
  background: #ffffff;
  padding: 20px 20px;
  box-sizing: border-box;
}
.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.enterpriseIntro {
  width: 260px;
  overflow: hidden; //超出隐藏
  text-overflow: ellipsis; //溢出用省略号显示
  display: -webkit-box; // 将对象作为弹性伸缩盒子模型显示。
  // 控制行数
  -webkit-line-clamp: 2; //超出两行隐藏
  -webkit-box-orient: vertical; // 从上到下垂直排列子元素
  color: #333333;
  position: relative;
}
.enterpriseIntroShow {
  position: absolute;
  border-radius: 8px;
  width: 260px;
  padding: 10px 7px 8px 13px;
  background-color: #fff;
  z-index: 999999 !important;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
.card_left {
  display: flex;
  height: 100%;
  .show_tags {
    position: relative;
    width: 50px;
    padding: 5px;
    cursor: pointer;
    .tags_box {
      display: flex;
      flex-flow: row wrap;
      justify-content: left;
      width: 400px;
      padding: 5px;
      background-color: rgba(241, 241, 241, 0.6);
      box-shadow: 0px 2px 4px rgba(241, 241, 241, 0.25);
      position: absolute;
      left: 100%;
      top: 100%;
      z-index: 999 !important;
    }
  }
  .clue_img {
    position: relative;
    width: 160px;
    height: 104px;
    margin-right: 10px;
    border-radius: 1px;
    img {
      width: 160px;
      height: 104px;
    }
  }
  .one {
    background: #ff7d18;
  }
  .two {
    background: #599afb;
  }
  .three {
    background: #999999;
  }
  .four {
    background: #999999;
  }
  .img_left_top {
    position: absolute;
    width: 93px;
    height: 34px;
    background: #ff7d18;
    color: #fff;
    text-align: center;
    line-height: 34px;
    border-radius: 10px 10px 10px 0px;
    top: 0px;
    left: 0px;
    transform: translate(-10px, -10px);
  }

  .card_info {
    .row {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
    }
    .clue_name {
      font-size: 18px;
      font-weight: bolder;
    }
    /* .el-tag {
      height: 18px;
      margin-right: 10px;
      padding: 0 5px;
      line-height: 16px;
      font-size: 12px;
    } */
    .card_title {
      display: flex;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
    }
    .corp_name {
      font-size: 14px;
      line-height: 20px;
      padding-right: 10px;
      margin-right: 10px;
      border-right: #e8e8e8 1px solid;
    }
    .corp_website {
      color: #4e93fb;
      cursor: pointer;
    }
    .bottom_row {
      display: flex;
      font-size: 14px;
      line-height: 20px;
    }
    .clue_num {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #6e6e6e;
      font-size: 14px;
      margin-right: 10px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
      }
    }
  }
}
.card_right {
  display: flex;
  flex-direction: column;
  align-items: center;
  .btn_list {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }
  .relevance {
    width: 100px;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    color: #4e93fb;
    text-align: center;
    margin: 10px 0 0 auto;
    background: #f5f5f5;
    border-radius: 5px;
  }
}

.el-card {
  .card_top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    margin-bottom: 20px;

    .left {
      width: 50%;
    }
    .right {
      font-size: 12px;
    }
  }
  .search {
    margin-bottom: 20px;
  }
}

.el-table {
  width: 100%;
  .logo {
    width: 52px;
    height: 52px;
    margin-right: 10px;
    img {
      width: 52px;
      height: 52px;
    }
  }
}
.span_tag {
  font-size: 14px;
  line-height: 20px;
  color: #4e93fb;
  margin-right: 10px;
  cursor: pointer;
}

.selected_top {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0px 20px 20px 10px;
  .selected_top_left {
    display: flex;
    align-items: flex-end;
    .clue_num {
      margin-left: 20px;
    }
    .el-button {
      margin-right: 10px;
    }
  }
  .set_font {
    font-size: 14px;
    color: #7c7f8e;
    margin-top: auto;
  }
}
.search_right {
  width: 500px;
  margin-right: 40px;
  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}
.card_item {
  width: 100%;
  margin: 10px;
  box-sizing: border-box;
  .card_header {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    .item_checkbox {
      line-height: 36px;
      margin: 0px 10px;
    }

    .selected_card {
      position: relative;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 20%;
      margin-top: 10px;
      padding: 10px;
      background: #ffffff;
      box-sizing: border-box;
      .selected_right_top {
        position: absolute;
        width: 100px;
        height: 30px;
        background-color: #f96060;
        color: #fff;
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        border-radius: 10px 2px 2px 10px;
        top: 0px;
        right: 10px;
      }
      .selected_card_left {
        display: flex;
        .card_title {
          margin-bottom: 10px;
          .name {
            font-size: 18px;
            font-weight: bolder;
            line-height: 25px;
            margin-right: 15px;
          }
          .path {
            font-size: 12px;
            line-height: 17px;
            color: #999;
          }
        }
        .logo {
          width: 52px;
          height: 52px;
          margin-right: 10px;
          img {
            width: 52px;
            height: 52px;
          }
        }
       
      }
      .selected_card_right {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-right: 100px;
        .el-button {
          background-color: #f4f4f4;
          border: 1px solid #c9c9c9;
          border-radius: 1px;
          margin-right: 20px;
        }
        .remove {
          font-size: 16px;
          line-height: 22px;
          color: #999;
          cursor: pointer;
        }
      }
    }
  }

}
.correlation_box {
  width: 55px;
  height: 24px;
  background: #fd6161;
  color: #fff;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  padding: 5px;
  border-radius: 2px;
}
.card_tag {
  display: flex;
  justify-content: left;
  .card_tag_flex {
    border: 1px solid #559eff;
    color: #4e93fb;
    box-sizing: border-box;
    padding: 1px 6px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  .office {
    border: 1px solid #559eff;
    color: #4e93fb;
    padding: 1px 6px;
    margin-right: 10px;
    cursor: pointer;
  }

  .support {
    border: 1px solid #fd523f;
    color: #fd523f;
    margin-left: 12px;
  }
}

</style>
